<template>
  <layout :drawer="true">
    <template slot="nav-tools">
      <smart-btn class="mr-1" @click.native="openEventListing = true">
        <span class="pl-1">Historique des évènements</span>
      </smart-btn>
      <smart-btn class="mr-1" @click.native="openEventEditor = true">
        <v-icon>mdi-folder-plus</v-icon>
        <span class="pl-1">Nouvel évènement</span>
      </smart-btn>
    </template>
    <template slot="drawer">
      <v-row
      >
        <v-col>
          <v-form
              ref="searchform"
              v-if="searchform"
              v-on:submit.prevent="search"
          >
            <v-row no-gutters class="pt-2 pr-1">
              <v-col cols="12" class>
                <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
                >Date de réservation
                </v-subheader
                >
                <v-divider></v-divider>
              </v-col>
              <v-col cols="6" class="pr-1">
                <smart-picker
                    v-model="searchform.bookingStartDate"
                    :stopDate="searchform.bookingStopDate"
                    :inputOpts="{ placeholder: ' ' }"
                    autocomplete="off"
                ></smart-picker>
              </v-col>
              <v-col cols="6" class="pr-1">
                <smart-picker
                    v-model="searchform.bookingStopDate"
                    :startDate="searchform.bookingStartDate"
                    :inputOpts="{ placeholder: ' ' }"
                    autocomplete="off"
                ></smart-picker>
              </v-col>
              <v-col cols="12" class>
                <v-subheader light class="pa-0 mb-0 mx-0 form-subheader"
                >Date de départ
                </v-subheader
                >
                <v-divider></v-divider>
              </v-col>
              <v-col cols="6" class="pr-1 mb-6">
                <smart-picker
                    v-model="searchform.travelStartDate"
                    :stopDate="searchform.travelStopDate"
                    :inputOpts="{ placeholder: ' ' }"
                    autocomplete="off"
                ></smart-picker>
              </v-col>
              <v-col cols="6" class="pr-1">
                <smart-picker
                    v-model="searchform.travelStopDate"
                    :startDate="searchform.travelStartDate"
                    :inputOpts="{ placeholder: ' ' }"
                    autocomplete="off"
                ></smart-picker>
              </v-col>

              <smart-btn type="submit" block primary @click="search"
              >Rechercher
              </smart-btn
              >
              <smart-btn block tertiary @click.native="clearSearchform()"
              >Réinitialiser la recherche
              </smart-btn
              >
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </template>
    <template slot="content">
      <v-container fluid>
        <v-row justify="space-between">
          <v-col cols="12" class="mb-3">
            <v-card class="pa-0 ma-0 blue-grey lighten-1">
              <widget-crm-agency-resume
                  :bus="widgetBus"
                  :url="`/api/agency/${id}/resume`"
              ></widget-crm-agency-resume>
            </v-card>
          </v-col>
        </v-row>

        <v-row dense class="justify-center">
          <v-col cols="12" md="6" class="my-0 py-0">
            <widget-agencies-ca-by-desti
                :bus="widgetBus"
                url="/api/widget/agency-stats/ca-destination"
                :dense="true"
            ></widget-agencies-ca-by-desti
            >
          </v-col>
          <v-col cols="12" md="6" class="my-0 py-0">
            <widget-crm-top-agent
                :bus="widgetBus"
                :url="`/api/agency/${id}/topagent`"
            ></widget-crm-top-agent>
          </v-col>
        </v-row>
        <div class="d-flex"
             :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'flex-column' : 'align-center'">
          <div class="d-flex title ml-2"
               :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'flex-column' : ''">
            <p class="mb-0">Informations agence: {{ form.name }} -</p>
            <p class="mb-0" v-if="form.termType">{{
                $t("paymentterm." + form.termType)
              }}</p>
          </div>
          <div class="d-flex mb-0 ml-4 mt-4">
            <v-switch v-model="editForm" class="ma-0 mb-0"></v-switch>
            <p class="mb-0 mt-1">Modification</p>
          </div>
        </div>
        <crm-agency-info v-if="editForm" :data="form"></crm-agency-info>
        <CrmAgencyInfoDetails v-else :data="form"></CrmAgencyInfoDetails>
        <v-tabs v-model="tabsListing" class="mt-4">
          <v-tab>Agents</v-tab>
          <v-tab v-if="canSeeTabs">Dossiers</v-tab>
          <v-tab v-if="canSeeTabs">Mises à jour</v-tab>
          <v-tab-item>
            <crm-agency-agents
                :id="id"
                :agents="agents"
                @success="agentSaved()">
            </crm-agency-agents>
          </v-tab-item>
          <v-tab-item eager>
            <bookings-list-table
                @loading="setLoading"
                ref="bookingListTable"
                class="bookingListTable"
            ></bookings-list-table>
          </v-tab-item>
          <v-tab-item>
            <CrmAgencyLogs :agency-id="id"/>
          </v-tab-item>
        </v-tabs>

        <smart-dialog
            v-if="openEventEditor"
            :title="dataReport == null ? 'Créer un évènement' : 'Editer l\'évènement'"
            v-model="openEventEditor"
            :width="900"
            @close="closeEvent"
            :fullscreen="_isMobile()"
        >
          <CrmNewEvent @closeEvent="closeEvent()" :selectorAgents="selectorAgents"
                       :agencyId="dataReport != null ? dataReport.agencyId : parseInt(id)" :dataReport="dataReport"/>
        </smart-dialog>
        <smart-dialog
            v-if="openEventListing"
            title="Liste des évènements"
            v-model="openEventListing"
            :width="700"
            @close="onCloseListing()"
            :fullscreen="_isMobile()"

        >
          <CrmAgencyEvents :id="id" :showTitle="false" inDialog class="pa-4"></CrmAgencyEvents>
        </smart-dialog>
      </v-container>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout";
import {get} from "@/utils/api";
import WidgetAgenciesCaByDesti from "../../components/commons/widgets/WidgetAgenciesCaByDesti";
import Vue, {nextTick} from "vue";
import WidgetCrmTopAgent from "../../components/commons/widgets/WidgetCrmTopAgent.vue";
import WidgetCrmAgencyResume from "../../components/commons/widgets/WidgetCrmAgencyResume.vue";
import CrmAgencyInfo from "../../components/commons/CrmAgencyInfo.vue";
import CrmAgencyInfoDetails from "../../components/commons/CrmAgencyInfoDetails.vue";
import CrmAgencyAgents from "../../components/commons/CrmAgencyAgents.vue";
import CrmAgencyEvents from "@/components/commons/CrmAgencyEvents.vue";
import FormMixin from "../../components/mixins/FormMixin";
import {EventBus} from "@/components/commons/event-bus";
import CrmNewEvent from "@/components/commons/CrmNewEvent.vue";
import BookingsListTable from "@/components/commons/BookingsListTable.vue";
import SiteLabel from "@/components/commons/SiteLabel.vue";
import ComparatorJson from "@/views/hlagons/ComparatorJson.vue";
import CrmAgencyLogs from "@/views/stats/CrmAgencyLogs.vue";

export default {
  name: "CrmAgencyDetails",
  mixins: [FormMixin],
  components: {
    CrmAgencyLogs,
    ComparatorJson,
    SiteLabel,
    BookingsListTable,
    CrmAgencyEvents,
    CrmNewEvent,
    Layout,
    WidgetAgenciesCaByDesti,
    WidgetCrmTopAgent,
    WidgetCrmAgencyResume,
    CrmAgencyInfo,
    CrmAgencyAgents,
    CrmAgencyInfoDetails
  },
  props: ["id"],
  data() {
    return {
      widgetBus: new Vue(),
      openEventEditor: false,
      openEventListing: false,
      agents: [],
      selectorAgents: [],
      searchform: {
        bookingStartDate: null,
        bookingStopDate: null,
        travelStartDate: null,
        travelStopDate: null,
      },
      form: {
        name: null,
        termType: null,
      },
      editForm: false,
      dataReport: null,
      tabsListing: "agents",

    };
  },

  created() {
    this.getAgency();
    this.searchform.bookingStartDate = this._formatDate(
        this._getFirstYearDay(this._now()),
        this.datePatternConfig.serverLocalDate
    );
    this.searchform.bookingStopDate = this._formatDate(
        this._now(),
        this.datePatternConfig.serverLocalDate
    );
    this.getAgents();
    this.search();

    EventBus.$on("agency.event.edit", (dataReport) => {
      this.getAgents(dataReport.agencyId)
      this.dataReport = dataReport
      this.openEventEditor = true
    })
    EventBus.$on("agent.migration.done", () => {
      this.getAgents()
    });
    EventBus.$on("agent.fusion.done", () => {
      this.getAgents()
    });
  },
  mounted() {
    this.loadBookings()
  },
  computed: {
    canSeeTabs() {
      return this.isAdmin || this.isSalma
    }
  },
  destroyed() {
    EventBus.$off("agent.migration.done")
    EventBus.$off("agent.fusion.done")
  },
  methods: {
    search() {
      let form = {
        ...this.searchform,
        agencies: [this.id],
        bookingStartDate: this.searchform.bookingStartDate
            ? this._parseAndFormatDate(
                this.searchform.bookingStartDate,
                this.datePatternConfig.serverLocalDate,
                this.datePatternConfig.serverLocalDateTime
            )
            : null,
        bookingStopDate: this.searchform.bookingStopDate
            ? this._parseAndFormatDate(
                this.searchform.bookingStopDate,
                this.datePatternConfig.serverLocalDate,
                this.datePatternConfig.serverLocalDateTime
            )
            : null,
        travelStartDate: this.searchform.travelStartDate
            ? this._parseAndFormatDate(
                this.searchform.travelStartDate,
                this.datePatternConfig.serverLocalDate,
                this.datePatternConfig.serverLocalDateTime
            )
            : null,
        travelStopDate: this.searchform.travelStopDate
            ? this._parseAndFormatDate(
                this.searchform.travelStopDate,
                this.datePatternConfig.serverLocalDate,
                this.datePatternConfig.serverLocalDateTime
            )
            : null,
      };
      setTimeout(() => {
        this.widgetBus.$emit("search", form)
      }, 500);
    },
    clearSearchform() {
      this.searchform = {
        bookingStartDate: null,
        bookingStopDate: null,
        travelStartDate: null,
        travelStopDate: null,
      };
    },

    getAgency() {
      const url = `/api/agency/${this.id}`;
      get(url)
          .badRequest((br) => console.log("Error: ", br))
          .json((data) => {
            this.form = data;
          })
    },
    getAgents(agencyId = this.id) {
      const url = `/api/agency/${agencyId}/agents`;

      return get(url)
          .badRequest((br) => console.log("Error: ", br))
          .json((data) => {
            return data.map((agent) => {
              return {
                id: agent.id,
                lastname: agent.lastname ? agent.lastname : "-",
                firstname: agent.firstname ? agent.firstname : "-",
                email: agent.email ? agent.email : "-",
                login: agent.login ? agent.login : "-",
                password: null,
                phone: agent.phone ? agent.phone : "-",
                active: agent.active || agent.active == null,
                isFromSmartresas: agent.isFromSmartresas,
                agencyName: agent.agencyName ? agent.agencyName : null,
                allowIncentive: agent.allowIncentive,
                incentives: agent.incentives,
                listIncentives: agent.incentives.availableIncentives
              };
            });
          })
          .then((r) => {
            this.agents = r;
            this.selectorAgents = r.filter(agent => agent.active).map((item) => {
              return {
                id: item.id,
                name: item.firstname + " " + item.lastname,
              };
            });
          });
    },
    loadBookings() {
      const form = {
        selectedSites: [],
        selectedNetworks: [],
        selectedAgencies: [this.id],
        selectedChains: [],
        selectedHotels: [],
        selectedDestinations: [],
        supplierStatus: [],
        client: null,
        ref: null,
        bookingDateStart: null,
        bookingDateStop: null,
        tripDateStart: null,
        tripDateStop: null,
        bookingStatus: ['booked', 'cancelled'],
        paymentStatus: null,
        customerId: null,
        tags: []
      };
      nextTick(() => {
        this.$refs.bookingListTable.load("/api/widget/bookings", form)
      })
    },
    closeEvent() {
      this.openEventEditor = false
    },
    onCloseListing() {
      this.openEventListing = false
    },
    agentSaved() {
      this.getAgents();
    },
    setLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>
<style></style>
