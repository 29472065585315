<template>
  <div class="pl-3 pr-3 pb-3">
    Du {{ _parseAndFormatDate(prestation.start, "dd/MM/yyyy HH:mm:ss", "dd/MM/yyyy") }} au
      {{ _parseAndFormatDate(prestation.stop, "dd/MM/yyyy HH:mm:ss", "dd/MM/yyyy") }}

  <ul>
    <li class="font-weight-medium mt-2">{{ prestation.name }} / {{ prestation.mealplanName }}</li>
    <li>
      <guest-list :guests="prestation.guests"></guest-list>
    </li>

    <li>Contract: {{ prestation.marketId }}, Market: {{ prestation.market }}</li>
    <li>
      <v-row>
        <v-col cols="6">
          Offres
          <ul>
            <li v-for="o in prestation.data.offers" :key="o.id">{{ o.name }} ({{ o.amount }}€)</li>
          </ul>
        </v-col>
        <v-col cols="6" v-if="prestation.extras && prestation.extras.length > 0">
          Extras
          <ul>
            <li v-for="e in prestation.extras" :key="e.id">{{ e.name }}</li>
          </ul>
        </v-col>
      </v-row>
    </li>
  </ul>
    </div>
</template>
<script>
import {defineComponent} from "vue";
import GuestList from "@/components/commons/GuestList.vue";

export default defineComponent({
  components: {GuestList},
  props: {
    booking: {type: Object, required: true},
    prestation: {type: Object, required: true},
    guests: {type: Array, required: true},
  },
})
</script>