<template>
  <div class="">
    <v-row>
      <v-col cols="3" style="border-right: 1px solid lightgrey;">
        <div v-if="logs.items == null || logs.items.length == 0 " class="pa-3">Aucune mise à jour disponible</div>
        <v-list>
          <v-list-item-group
              v-model="logs.selected"
              color="primary">
            <v-list-item
                         v-for="(item, i) in logs.items"
                         :key="i"
                         @click="selectLog(item,i)"
                         :value="item">

              <v-list-item-content>

                <div class="d-flex flex-row justify-start align-center">

                  <span class="grey--text">{{ item.version }}</span>
                  <site-label class="mx-2" :sitename="item.agentId != null ? 'HLagons' : 'Smartresas'"/>
                  <span class="font-weight-medium">{{
                      _formatDate(new Date(item.creationDate), "dd/MM/yyyy 'à' HH:mm")
                    }}</span>
                  <span class="mx-2">par</span>

                  <span class="font-weight-medium" v-if="item.agentId != null">{{ item.agentName }}</span>
                  <span class="font-weight-medium" v-if="item.userId != null">{{ item.userName }}</span>

                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col cols="9">
        <div class="px-3">

          <div v-if="logs.newestSelected != null && logs.selected != null" class="d-flex flex-column ">
            <comparator-json :obj2="logs.oldestSelected" :obj1="logs.newestSelected" :hide-fields="['version','password']"/>

          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SiteLabel from "@/components/commons/SiteLabel.vue";
import ComparatorJson from "@/views/hlagons/ComparatorJson.vue";
import {get} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";

export default {
  name: 'CrmAgencyLogs',
  components: {ComparatorJson, SiteLabel},
  props: {
    agencyId: {type: [String, Number], required: true},
  },
  data() {
    return {
      logs: {
        selected: null,
        newestSelected: null,
        oldestSelected: null,
        items: null,
        loading: false,
      }
    }
  },
  created() {
    EventBus.$on("reloadLogs", () => {
      this.init()
    })
    this.init()
  },
  methods: {
    init() {
      this.logs.items = null
      this.logs.loading = true
      const url = `/api/agency/${this.agencyId}/logs`;
      get(url)
          .badRequest((br) => console.log("Error: ", br))
          .json((data) => {
            this.logs.actual = data.shift()

            this.logs.actual.agency.version = "Version enregistrée"
            this.logs.items = [...data.map(d => {
              d.agency.version = "#" + d.agencyId + "-" + d.id
              return {...d, version: "#" + d.agencyId + "-" + d.id}
            })]
            this.logs.loading = false
            if (this.logs.items.length > 0) {
              this.selectLog(this.logs.items[0], 0)
            } else {
              this.logs.selected = null
            }
          })
    },
    selectLog(item, i) {
      this.logs.selected = item
      if (i == 0) {
        this.logs.oldestSelected = this.logs.actual.agency
      } else {
        this.logs.oldestSelected = this.logs.items[i - 1].agency
        this.logs.oldestSelected.version = "#" + this.agencyId + "-" + this.logs.items[i - 1].id
      }
      this.logs.newestSelected = item.agency
      this.logs.newestSelected.version = "#" + this.agencyId + "-" + item.id
    },
  }
}
</script>