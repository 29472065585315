<template>
  <layout>
    <template slot="nav">
      <nav-currencies />
    </template>

    <template slot="drawer"></template>
    |
    <template slot="content">
      <v-container fluid>
        <v-card>
          <v-data-table
            dense
            :headers="headers"
            :items="rawData"
            :loading="loading"
            no-data-text="-"
            hide-default-footer
            :footer-props="{
              'items-per-page-options': rowsPerPageItems,
              'items-per-page-text': 'Lignes par page',
            }"
            class="tbl"
          >
            <template #item.prevTotal="{ item }"
              ><span class="font-weight-medium">{{
                formattedAmount(item.prevTotal)
              }}</span></template
            >
            <template #item.paidTotal="{ item }"
              ><span class="font-weight-medium">{{
                formattedAmount(item.paidTotal)
              }}</span></template
            >
            <template #item.total="{ item }"
              ><span class="font-weight-medium">{{
                formattedAmount(item.prevTotal + item.paidTotal)
              }}</span></template
            >
            <template
              v-for="m in headersMonths"
              v-slot:[`item.${m.value}`]="{ item, value }"
            >
              <template v-for="p in [getMonth(item.periods, m.value)]">
                <div
                  v-if="p"
                  :key="item.euroRate + '-' + p.date"
                  @click="cellClicked(p.date, item.euroRate)"
                >
                  {{ formattedAmount(p.prevAmount) }}
                </div>
              </template>
            </template>
            <template #body.append="{ items }">
              <tr>
                <td>Total</td>
                <td class="font-weight-medium text-right">
                  {{
                    formattedAmount(
                      items.reduce((sum, item) => sum + item.prevTotal, 0)
                    )
                  }}
                </td>
                <td class="font-weight-medium text-right">
                  {{
                    formattedAmount(
                      items.reduce((sum, item) => sum + item.paidTotal, 0)
                    )
                  }}
                </td>
                <td class="font-weight-medium text-right">
                  {{
                    formattedAmount(
                      items.reduce(
                        (sum, item) => sum + item.prevTotal + item.paidTotal,
                        0
                      )
                    )
                  }}
                </td>
                <td
                  v-for="(m, index) in headersMonths"
                  :key="'qq' + index"
                  class="text-right font-weight-medium"
                >
                  {{ formattedAmount(sumPeriod(items, m.value, "prevAmount")) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>

        <v-card
          class="mt-4"
          v-if="
            prestationsNotInvoiced.length > 0 || prestationsInvoiced.length > 0
          "
        >
          <v-row>
            <v-col cols="6">
              <h4 class="mb-2 ml-2 text-decoration-underline">
                Prestations non attachées à une facture
              </h4>
              <v-data-table
                v-if="prestationsNotInvoiced.length > 0"
                dense
                :headers="headersPrestation"
                :items="prestationsNotInvoiced"
                no-data-text="-"
                hide-default-footer
                :footer-props="{
                  'items-per-page-options': rowsPerPageItems,
                  'items-per-page-text': 'Lignes par page',
                }"
                class="tbl"
              >
                <template #item.purchase="{ value }">
                  {{ formattedAmount(value) }}
                </template>
              </v-data-table>

              <v-divider></v-divider>
              <div
                class="font-weight-bold text-right mr-1 mt-1"
                v-if="prestationsNotInvoiced.length > 0"
              >
                Total: {{ formattedAmount(sumPrestationsNotInvoiced) }}
              </div>
              <div v-else>-</div>
            </v-col>

            <v-col cols="6">
              <h4 class="mb-2 ml-2 text-decoration-underline">
                Factures saisies
              </h4>
              <v-data-table
                v-if="prestationsInvoiced.length > 0"
                dense
                :headers="headersInvoices"
                :items="prestationsInvoiced"
                no-data-text="-"
                hide-default-footer
                :footer-props="{
                  'items-per-page-options': rowsPerPageItems,
                  'items-per-page-text': 'Lignes par page',
                }"
                class="tbl"
              >
                <template #item.amount="{ value }">
                  {{ formattedAmount(value) }}
                </template>
              </v-data-table>
              <v-divider></v-divider>
              <div
                class="font-weight-bold text-right mr-1 mt-1"
                v-if="prestationsInvoiced.length > 0"
              >
                Total: {{ formattedAmount(sumPrestationsInvoiced) }}
              </div>
              <div v-else>-</div>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </template>
  </layout>
</template>

<script>
import Layout from "@/views/Layout";
import NavCurrencies from "@/views/accounting/currenciespayments/NavCurrencies";
import CurrenciesPaymentTable from "./CurrenciesPaymentTable.vue";
import { EventBus } from "@/components/commons/event-bus";
import { get, post } from "@/utils/api";
import { format } from "date-fns";

export default {
  components: {
    Layout,
    NavCurrencies,
    CurrenciesPaymentTable,
  },
  data: () => ({
    loading: false,
    rawData: [],

    prestationsInvoiced: [],
    prestationsNotInvoiced: [],

    rowsPerPageItems: [{ text: "Tous", value: -1 }, 50, 200],

    headersPrestation: [
      { text: "Dos", value: "bookingId", sortable: true },
      { text: "Presta", value: "id", sortable: true },
      { text: "Nom", value: "name", sortable: false },
      { text: "Montant", value: "purchase", sortable: false, align: "right" },
    ],
    headersInvoices: [
      { text: "Fac", value: "id", sortable: true },
      { text: "prestation ", value: "prestation", sortable: false },
      { text: "Montant", value: "amount", sortable: false, align: "right" },
    ],
  }),
  created() {
    const url = `/api/accounting/usd-covering`;
    this.loading = true;
    get(url)
      .json((r) => {
        this.rawData = r;
      })
      .then((d) => {
        this.loading = false;
      });
  },
  computed: {
    headers() {
      return [
        {
          text: "Euro Rate",
          value: "euroRate",
          sortable: false,
          divider: true,
        },
        {
          text: "Total non reglé",
          value: "prevTotal",
          sortable: false,
          divider: true,
          align: "right",
        },
        {
          text: "Total reglé",
          value: "paidTotal",
          sortable: false,
          divider: true,
          align: "right",
        },
        {
          text: "Total",
          value: "total",
          sortable: false,
          divider: true,
          align: "right",
        },
        ...this.headersMonths,
      ];
    },
    headersMonths() {
      const months = this.rawData
        .flatMap((r) => r.periods.map((p) => p.date))
        .filter((s, i, a) => a.indexOf(s) == i)
        .map((d) => {
          return {
            text: d.substring(0, 7),
            value: d,
            sortable: false,
            divider: true,
            align: "right",
          };
        }).sort((a,b) => this._isBefore(new Date(a.value),new Date(b.value))? -1: 0)

      return months
    },
    sumPrestationsInvoiced() {
      return this.prestationsInvoiced.reduce((sum, p) => sum + p.amount, 0);
    },

    sumPrestationsNotInvoiced() {
      return this.prestationsNotInvoiced.reduce(
        (sum, p) => sum + p.purchase,
        0
      );
    },
  },
  methods: {
    getMonth(periods, date) {
      return periods.find((p) => p.date == date);
    },
    cellClicked(period, rate) {
      const url = `/api/accounting/usd-covering/check?period=${format(
        new Date(period),
        "MM-yyyy"
      )}&rate=${rate}`;
      get(url).json((r) => {
        this.prestationsNotInvoiced = r.prestationsNotInvoiced;
        this.prestationsInvoiced = r.prestationsInvoiced;
      });
    },
    formattedAmount(amount) {
      return this.$options.filters
        .currency(amount, "EUR", { maximumFractionDigits: 0 })
        .replace("€", "");
    },
    sumPeriod(items, period, key) {
      return items.reduce((sum, item) => {
        const p = item.periods.find((p) => p.date == period);
        return sum + (p ? p[key] : 0);
      }, 0);
    },
  },
};
</script>
<style scoped></style>
