<template>
  <layout :drawer="true" :drawerShow="true" :drawerWidth="400">
    <template slot="nav">
      <v-tabs>
        <v-tab :to="{ name: 'customer-search' }">Clients</v-tab>
      </v-tabs>
    </template>
    <template slot="drawer">
      <v-row>
        <v-col>
          <v-form v-on:submit.prevent @submit="searchCustomer">
            <v-text-field
              v-model="searchTerm"
              prepend-inner-icon="mdi-magnify"
              :label="searchTerm == '' ? '' : 'Recherche'"
              placeholder="Rechercher un client par nom, prénom, email"
            ></v-text-field>
            <smart-btn
              block
              primary
              :loading="isLoading"
              @click.native="searchCustomer"
              :disabled="searchTerm === ''"
              >Rechercher</smart-btn
            >
          </v-form>
        </v-col>
      </v-row>
    </template>
    <template slot="content">
      <v-container fluid>
        <v-row justify="center">
          <v-data-table
            class="elevation-1 tbl"
            :headers="headers"
            :items="users"
            :loading="isLoading"
            :no-data-text="noDataSentence"
            :options.sync="listing.pagination"
            :footer-props="{
              'items-per-page-options': listing.rowsPerPageItems,
              'items-per-page-text': 'Lignes par page',
            }"
            :server-items-length="pagination.total"
            @click:row="openUserProfile"
            @update:page="paginate"
            @update:items-per-page="paginate"
            style="width: 810px"
          />

        </v-row>
      </v-container>
    </template>
  </layout>
</template>
<script>
import { get } from "@/utils/api";
import Layout from "../Layout";

export default {
  name: "CustomersList",
  components: {
    Layout,
  },
  data() {
    return {
      headers: [
        { sortable: false, text: "", value: "data-table-expand" },
        { sortable: true, text: "Nom", align: "left", value: "lastname" },
        { sortable: false, text: "Prénom", align: "left", value: "firstname" },
        { sortable: false, text: "Email", align: "left", value: "email" },
        { sortable: false, text: "Devis", align: "left", value: "quotations" },
        { sortable: false, text: "Dossiers", align: "left", value: "bookings" },
      ],
      noDataSentence: "Veuillez effectuer une recherche",
      listing: {
        loading: false,
        rowsPerPageItems: [10, 50, 200],
        itemsPerPage: 50,
      },
      searchTerm: "",
      users: [],
      pagination: {},
      isLoading: false,
      expandArrow: false,
      prevPath: "",
    };
  },
  created() {
    this.initSearchform();
    this.loadData();
  },
  methods: {
    paginate(page) {
      this.searchCustomer({
        offset: this.listing.itemsPerPage * (page - 1),
        limit: this.listing.itemsPerPage,
      });
    },
    searchCustomer(pagination) {
      if (!this.isLoading) {
        const params = {
          ...{
            search: this.searchTerm,
            limit: this.listing.itemsPerPage,
          },
          ...pagination,
        };

        const qs = Object.keys(params)
          .map((key) => {
            return `${key}=${params[key]}`;
          })
          .join("&");

        this.isLoading = true;
        const url = "/api/customer/search";

        get(url + "?" + qs)
          .badRequest(() => {
            this.noDataSentence = "Aucun client trouvé";
          })
          .json((data) => {
            this.users = data.customers
            this.pagination = data.pagination;
            if (data.customers.length == 0) {
              this.noDataSentence = "Aucun client trouvé";
            }
          })
          .then(() => {
            this.isLoading = false;
            this.loadData();
          });
      }
    },
    openUserProfile(item) {
      this.$router.push({ name: "customer-edition", params: { id: item.id } });
    },

    loadData() {
      //set qs
      window.history.pushState(
        "",
        "",
        "?search=" + JSON.stringify(this.searchTerm)
      );
    },
    initSearchform() {
      const qs = new URLSearchParams(window.location.search);
      const search = (() => {
        try {
          return JSON.parse(qs.get("search"));
        } catch (e) {
          return null;
        }
      })();

      if (search) {
        this.searchTerm = search;
        this.searchCustomer();
      } else this.searchTerm = "";
    },
  },
};
</script>
<style scoped></style>
