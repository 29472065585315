export default {

	data: function() {
		return {
			colorsStatusList: {
				'cart': { color: "amber lighten-5", text:"amber--text text--darken-3", border:"#FFECB3" },
				'quotation': { color: "blue lighten-5", text:"blue--text text--darken-3", border:"#90CAF9" },
				'option': { color: "brown lighten-5", text:"brown--text text--darken-3", border:"#D7CCC8" },
				'booked': { color: "green lighten-5", text:"green--text text--darken-3", border:"#C8E6C9" },
				'cancelled': { color: "red lighten-5", text:"red--text text--darken-3", border:"#FFCDD2" },
				'deleted': { color: "grey lighten-3", text:"black--text text--darken-3", border:"#616161" },
			},
			colorsSupplierStatusList: {
				'ondemand': { color: "amber lighten-5", text: "amber--text text--darken-3", border:'#FFECB3' },
				'option': { color: "brown lighten-5", text: "brown--text text--darken-3", border:'#A1887F'  },
				'confirmed': { color: "green lighten-5", text: "green--text text--darken-3", border:'#C8E6C9'  },
				'refused': { color: "red lighten-5", text: "red--text text--darken-3", border:'#FFCDD2'  },
				'cancelled': { color: "purple lighten-5", text: "purple--text text--darken-3", border:'#E1BEE7'},
			},
			colorsGiftcardStatusList: {
				'pending' :{ color: "amber lighten-5", text: "amber--text text--darken-3", border:'#FFECB3' },
				'validated': { color: "green lighten-5", text: "green--text text--darken-3", border:'#C8E6C9'  },
				'cancel': { color: "red lighten-5", text:"red--text text--darken-3", border:"#FFCDD2" }
			},
			colorsStockList:{
				'onstock': { color: "green lighten-5", text: "green--text text--darken-3", border:'#C8E6C9' },
				'freesell': { color: "green lighten-5", text: "green--text text--darken-3", border:'#C8E6C9'  },
				'onrequest': { color: "amber lighten-5", text: "orange--text text--darken-3", border:'#FFECB3'  },
				'soldout': { color: "red lighten-5", text: "red--text text--darken-3", border:'#FFCDD2'  },
			},
			colorsInvoicingList:{
				'sold': { color: "green lighten-5", text: "green--text text--darken-3" , border:'#C8E6C9'},
        'waiting': { color: "amber lighten-5", text: "orange--text text--darken-3", border:'#FFECB3' },
			},
			colorsPrestationList:{
				'RoomPrestation': { color: 'pink'} ,
				'TransferPrestation': { color: 'brown'} ,
				'CarrentalPrestation': { color: 'teal'} ,
				'FlightPrestation': { color: 'blue'} ,
				'Pnr': { color: 'blue'},
				'InsurancePrestation':{ color: 'purple'},
				'UntypedPrestation': { color: 'orange'}
			},
			colorsTaskList:{
				'AbsoluteEmergency': {color: "purple lighten-5", text: "purple--text text--darken-3", icon:"mdi-alarm-light"},
				'Emergency': {color: "red lighten-5", text: "red--text text--darken-3", icon:"notification_important"},
				'Normal': {color: "orange lighten-5", text: "orange--text text--darken-3", icon:"mdi-alert"},
				'Control': {color: "yellow lighten-5", text: "yellow--text text--darken-3", icon:"mdi-file-check"},
				'Info': {color: "light-blue lighten-5", text: "light-blue--text text--darken-3", icon:"mdi-information"},
			},
			taskLevelIndex:{
				1:"AbsoluteEmergency",
				2:"Emergency",
				3:"Normal",
				4:"Control",
				5:"Info",
			},
			colorSite:{
				'Hotelissima': {color:"deep-orange darken-3", text:"deep-orange--text text--darken-3", hex:"#D84315"},
				'HotelissimaRE': {color:"deep-orange darken-3", text:"deep-orange--text text--darken-3", hex:"#D84315"},
				'HResa': {color:"light-blue darken-3", text:"light-blue--text text--darken-3", hex:"#0277BD"},
				'DiversB2B': {color:"light-blue darken-3", text:"light-blue--text text--darken-3", hex:"#0277BD"},
				'B2B': {color:"light-blue darken-3", text:"light-blue--text text--darken-3", hex:"#0277BD"},
				'Evao': {color:"light-blue darken-3", text:"light-blue--text text--darken-3", hex:"#0277BD"},
				'HLagons': {color:"amber darken-3", text:"amber--text text--darken-3", hex:"#FF8F00"},
				'Hôtels & Lagons' : {color:"amber darken-3", text:"amber--text text--darken-3", hex:"#FF8F00"},
				'HLagonsItaly': {color:"amber darken-3", text:"amber--text text--darken-3", hex:"#FF8F00"},
				'ZilMaurice': {color:"pink darken-3", text:"pink--text text--darken-3", hex:"#AD1457"},
				'Zil Maurice': {color:"pink darken-3", text:"pink--text text--darken-3", hex:"#AD1457"},
				'ZilCaraibes': {color:"light-green darken-3", text:"light-green--text text--darken-3", hex:"#558B2F"},
				'Smartresas':{color:"blue lighten-1", text:"light-blue--text text--darken-3", hex:"#0277BD"}
			},
		}
	},
	methods: {
		statusColor(status){
			return this.colorsStatusList[status].color
		},
		statusColorText(status){
			return this.colorsStatusList[status].text
		},

		supplierStatusColor(status){
			return this.colorsSupplierStatusList[status].color
		},
		supplierStatusColorText(status){
			return this.colorsSupplierStatusList[status].text
		},

		stockColor(status){
			return this.colorsStockList[status].color
		},

		stockColorText(status){
			return this.colorsStockList[status].text
		},

		taskColor(taskLevel) {
			return this.colorsTaskList[taskLevel].color
		},

		taskTextColor(taskLevel) {
			return this.colorsTaskList[taskLevel].text
		},

		taskIcon(taskLevel) {
			return this.colorsTaskList[taskLevel].icon
		},

		prestationColor(kind){
			const obj = this.colorsPrestationList[kind] || {color:""}
      return obj.color
		},

		siteColor(sitename){
			return this.colorSite[sitename].color;
		},
		siteTextColor(sitename){
			return this.colorSite[sitename].text;
		},
		siteHexColor(siteName){
			return this.colorSite[siteName].hex;
		}
	}
}
