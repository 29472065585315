<template>
  <div class="pa-4">
    <v-form ref="form" v-on:submit.prevent="update">
      <v-row class="">
        <v-col cols="7">
          <v-text-field
              :rules="rules.nonEmpty()"
              v-model="form.name"
              label="Nom de la prestation"/>
        </v-col>
      </v-row>

      <v-divider class="my-4"/>
      <v-row dense>
        <v-col cols="8">
          <v-subheader light class="pa-0 mt-5 mb-0 form-subheader">
            Dates de voyage
          </v-subheader>
        </v-col>
        <v-col cols="5">


          <smart-picker
              :rules="rules.nonEmpty()"
              v-model="form.startDate"
              :stop-date="form.stopDate"
              :inputOpts="{ label: $t('begin') }"
          />
        </v-col>
        <v-col cols="5">

          <smart-picker
              :rules="rules.nonEmpty()"
              v-model="form.stopDate"
              :startDate="form.startDate"
              :inputOpts="{ label: $t('end') }"
          />
        </v-col>
      </v-row>
      <v-divider class="my-4"/>
      <v-row>
        <v-col cols="8">
          <v-subheader light class="pa-0 mt-5 mb-0 form-subheader">
            Voyageurs
          </v-subheader>
        </v-col>
        <v-col cols="12">
          <v-data-table
              show-select
              v-model="form.guests"
              :items="allGuests" item-key="id" class="tbl" hide-default-footer :headers="headers">
            <template #item.id="{item}">
              <span class="caption grey--text lighten-1">#{{ item.id }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <div class="d-flex justify-end mt-3">
        <smart-btn class="mx-1" type="button" @click.native="close()">
          Annuler
        </smart-btn>
        <smart-btn class="mx-1" type="submit">
          Valider
        </smart-btn>
      </div>
    </v-form>
  </div>
</template>
<script>
import {post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";
import formMixin from "@/components/mixins/FormMixin";

export default {
  name: "UntypedEdit",
  mixins: [formMixin],
  props: {
    prestation: {type: Object, required: true},
    bookingId: {type: Number, required: true},
    allGuests: {type: Array, default: () => []},
  },
  data() {
    return {
      form: {
        guests: [],
        name: null,
        startDate: null,
        stopDate: null,
      },
      headers: [
        {text: "id", value: "id", sortable: true},
        {text: "Nom", value: "lastName", sortable: true},
        {text: "Prénom", value: "firstName", sortable: true},
        {text: "Date de naissance", value: "birthdate", sortable: true},
        {text: "Type", value: "ageClassType", sortable: true},
      ]
    }
  },
  methods: {
    update() {
      if (this.$refs.form.validate()) {

        const form = {
          ...this.form,
          cancelFees: this.form.cancelFees.fees,
          startDate: this._parseAndFormatDate(this.form.startDate, "dd/MM/yyyy", this.datePatternConfig.serverLocalDateTime),
          stopDate: this._parseAndFormatDate(this.form.stopDate, "dd/MM/yyyy", this.datePatternConfig.serverLocalDateTime)
        }

        post(`/api/prestation/edit/${this.prestation.id}/untyped?bookingId=${this.bookingId}`, form).res(() => {
          EventBus.$emit("toaster-msg", "Prestation modifiée avec succès");
          EventBus.$emit('reloadbooking')
          this.close()
        })
      }
    },
    close() {
      this.$emit('close-dialog')
    },
  },
  created() {
    this.form = {
      name: this.prestation.name,
      status: this.prestation.status,
      supplierStatus: this.prestation.supplierStatus,
      startDate: this._parseAndFormatDate(this.prestation.start, this.datePatternConfig.serverLocalDateTime, "dd/MM/yyyy"),
      stopDate: this._parseAndFormatDate(this.prestation.stop, this.datePatternConfig.serverLocalDateTime, "dd/MM/yyyy"),
      price: this.prestation.price,
      purchase: this.prestation.purchase,
      purchaseCurrency: this.prestation.purchaseCurrency,
      cancelFees: this.prestation.cancelFees,
      supplier: this.prestation.supplierId,
      supplierAccounting: this.prestation.supplierAccountingId,
      destination: this.prestation.destination.id,
      guests: this.prestation.guests
    }
  }
}
</script>