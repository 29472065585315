<template>
  <div class="d-flex flex-column">
    <v-row dense class="">
      <v-col cols="2"></v-col>
      <v-col cols="4" class=" text-center">
        <span>Version précédente</span>
        <v-divider class="mt-3 mb-7"/>
      </v-col>
      <v-col cols="4" class=" text-center">
        <span>{{ obj2.version }} le: </span>
        <span class="font-weight-bold" v-if="obj2.modifyDate != null">{{
            _formatDate(new Date(obj2.modifyDate), datePatternConfig.fr.localDateWithTime)
          }}</span>
        <v-divider class="mt-3 mb-7"/>
      </v-col>
    </v-row>
    <div class="" v-for="(field, i) in fields" :key="i">
      <v-row dense class="">
        <v-col cols="2" class="font-weight-bold pr-3">{{ field }}</v-col>
        <v-col cols="4" class="pl-3 limit-left" :class="colorValue(field).obj1">{{ obj1[field] }}</v-col>
        <v-col cols="4" class="pl-3 limit-left" :class="colorValue(field).obj2">{{ obj2[field] }}</v-col>
      </v-row>
      <v-divider/>
    </div>
  </div>
</template>
<script>

export default {
  name: "ComparatorJson",
  mixins: [],
  data() {
    return {
      fields: [],
    }
  },
  props: {
    obj2: {type: Object, required: true},
    obj1: {type: Object, required: true},
    hideFields: {type: Array, required: false, default: () => []}
  },
  created() {
    this.init()
  },
  computed: {},
  methods: {
    init() {
      this.fields = [...Object.keys(this.obj2), ...Object.keys(this.obj1)].filter(
          (value, index, current_value) => current_value.indexOf(value) === index
      ).filter(f => {
        return  !this.hideFields.includes(f)
      })
    },
    isEmpty(value) {

      if (value == null) {
        return true
      } else if (typeof value == "boolean") {
        return false
      } else if (value.constructor === Array) {
        return value.length == 0
      } else if (value.constructor === Number) {
        return false
      } else {
        return value.trim() === ""
      }
    },
    nonEmpty(value) {
      return !this.isEmpty(value)
    },
    isSameObjectValues(arr1, arr2) {
      return JSON.stringify(arr1) === JSON.stringify(arr2)
    },
    colorValue(field) {

      // Removed value in latest
      if (this.isEmpty(this.obj2[field]) && (this.obj1[field] != null && this.obj1[field].toString().length > 0)) {
        return {obj2: 'green--text', obj1: 'red--text'}

      } else if (
          // Added value in latest
          this.isEmpty(this.obj1[field]) && (this.obj2[field] != null && this.obj2[field].toString().length > 0)
      ) {
        return {obj2: 'green--text', obj1: 'red--text'}
      } else if (
          // Edited value
          this.nonEmpty(this.obj2[field]) && this.nonEmpty(this.obj1[field])
          && (typeof this.obj2[field] == 'object' ? !this.isSameObjectValues(this.obj1[field], this.obj2[field]) : this.obj2[field] !== this.obj1[field])
      ) {
        return {obj2: 'blue--text', obj1: 'blue--text'}


      } else {
        // No changes
        return {obj2: '', obj1: ''}
      }
    }
    ,
  }
}
</script>
<style scoped>
.limit-left {
  border-left: 1px solid lightgray;
}
</style>