<template>
  <v-col>
    <v-alert :value="true" type="info" text alert-padding="0px" alert-margin="0">
      <div class="d-inline-flex flex-column">
      <span>Les extras affichés sont ceux disponibles au moment de l'ajout de la prestation. Les prix ont été figés à cette
      date.</span>
      <span>Le fournisseur sera prévenu par email de la modification.</span>
        </div>
    </v-alert>
    <v-row>
      <v-col cols="5">
        <HotelRoomDetails :prestation="prestation" :booking="booking" :guests="guests"/>
      </v-col>
      <v-col cols="5" class="d-flex">
        <v-divider vertical style="margin:0"></v-divider>
        <ul class="nostyle" v-if="prestation">
          <li>
            <v-row class="pl-3 pr-3 pb-3">
              <v-col cols="12" v-if="prestation.data.extras && prestation.data.extras.length>0">
                Extras
                <ul>
                  <li v-for="e in prestation.data.extras" :key="e.id" class="mt-2">
                    <v-checkbox v-model="e.included" :disabled="e.mandatory" dense hide-details class="ma-0">
                      <template #label>{{ e.name }} (+{{ e.price }}€)</template>
                    </v-checkbox>
                  </li>
                </ul>
              </v-col>
            </v-row>
          </li>
        </ul>
      </v-col>

    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" class="d-flex justify-space-">
        <v-row>
          <v-col cols="5" class="text-center headline">{{ roomOnlyPrice }}€</v-col>
          <v-col cols="5" class="text-center headline">+{{ propositionPrice }}€</v-col>
          <v-col cols="2" class="text-center headline">Total: {{ propositionPrice + roomOnlyPrice }}€</v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col cols="12" class="text-right">
        <v-btn @click="confirm">Modifier les extras</v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import _ from "lodash";

import {EventBus} from "@/components/commons/event-bus";
import {post} from "@/utils/api";
import HotelRoomDetails from "@/components/booking/hotel/HotelRoomDetails.vue";

export default {
  name: "ModifyRoom",
  components: {HotelRoomDetails},
  mixins: [],
  props: {
    booking: {type: Object, required: true},
    prestation: {type: Object, required: true},
    guests: {type: Array, required: true},
  },
  data: () => ({
    originPrestation: null,
    step: 1,
    ctx: {
      hotelId: null,
      bookingDate: "",
      startDate: "",
      stopDate: "",
      roomsRepartition: [],
      declarations: [],
      roomTypeIds: [],
      mealPlanTypeIds: [],
      extras: [],
      hotelSelections: [],
      env: {
        siteId: 1,
        langId: 1,
        priceClassTypeId: 2,
        strokePriceClassTypeId: 3,
        clientCountry: "FR",
        debug: false,
        withCache: false,
      },
    },
    allMeals: [
      {name: "Hébergement seul", mealPlanTypeId: 4},
      {name: "Petit-déjeuner", mealPlanTypeId: 3},
      {name: "Demi-Pension", mealPlanTypeId: 1},
      {name: "Pension complète", mealPlanTypeId: 2},
      {name: "All inclusive", mealPlanTypeId: 5},
    ],
    headers: [{text: "", sortable: false, align: "left"}],
    hotelprice: null,
    items: [],
    propositionSelected: null,
    pagination: {
      rowsPerPageItems: [{text: "Tous", value: -1}, 50, 200],
      page: 1,
      sortBy: [],
      sortDesc: [false],
    },
  }),
  computed: {

    propositionPrice() {
      const added = this.prestation.data.extras.filter((e) => e.included).map(e => e.price)
      return added.reduce((a, b) => {
        return a + b;
      }, 0);
    },
    roomOnlyPrice() {
      const includedExtras = this.originPrestation.data.extras.map(ex => ex.included ? ex.price : 0)
      return this.originPrestation.price - includedExtras.reduce((a, b) => {
        return a + b;
      }, 0);
    },
  },
  watch: {
    prestation() {
      this.init();
    },
  },
  created() {
    this.init();
    this.allMeals.map((meal) => {
      this.headers.push({
        text: meal.name,
        mealPlanTypeId: meal.mealPlanTypeId,
        sortable: false,
        align: "center",
        width: 150,
      });
    });
  },
  methods: {
    init() {
      this.originPrestation = JSON.parse(JSON.stringify(this.prestation))
      this.ctx = Object.assign({}, this.ctx, {
        hotelId: this.prestation.hotelId,
        bookingDate: this._nowFormatted(
            this.datePatternConfig.serverLocalDateTime
        ),
        startDate: this.prestation.start.slice(0, 10),
        stopDate: this.prestation.stop.slice(0, 10),
        roomsRepartition: [
          {guests: this.prestation.guests, repartition: "SAMEROOM"},
        ],
        declarations: this.booking.context.declarations,
        env: {
          siteId: this.booking.context.site.id,
          langId: this.booking.context.lang.id,
          priceClassTypeId: this.booking.context.priceClassType.id,
          strokePriceClassTypeId: this.booking.context.strokePriceClassType.id,
          clientCountry: this.booking.context.countryCode,
          debug: false,
          withCache: false,
        },
      });

      this.search();
      console.log('prestation', this.prestation)
    },

    search() {
      post(`/api/prestation/add/search`, {
        search: {kind: "hotel", name: "", id: this.ctx.hotelId},
        ctx: this.ctx,
      })
          .badRequest((err) => {
            EventBus.$emit(
                "toaster-msg",
                JSON.parse(err.null).errors.map((e) => JSON.parse(e))
            );
          })
          .json((data) => {
            this.hotelprice = data[0];

            this.items = [];
            this.hotelprice.hotel.propositions.map((p) => {
              const roomName = p.rooms.map((r) => r.name).join(", ");
              const rooms = _.intersectionBy(
                  this.hotelprice.hotel.rooms,
                  p.rooms,
                  "roomId"
              );

              var index = _.findIndex(this.items, (i) => {
                return i.roomName === roomName;
              });
              if (index === -1) {
                this.items.push({
                  roomName: roomName,
                  rooms: rooms,
                  propositions: [p],
                });
              } else {
                this.items[index].propositions.push(p);
              }
            });

            this.items = _.map(this.items, (item) => {
              item.meals = _.map(this.allMeals, (meal) => {
                const prop = item.propositions.find((p) => {
                  return (
                      _.findIndex(p.meals, (m) => {
                        return m.mealPlanTypeId === meal.mealPlanTypeId;
                      }) != -1
                  );
                });

                return prop;
              });
              return item;
            });
          });
    },
    select(item) {
      this.propositionSelected = item;
      this.step = 2;
    },
    confirm() {
      const ctx = Object.assign({}, this.ctx, {
        startDate: this.prestation.start.split(" ")[0],
        stopDate: this.prestation.stop.split(" ")[0],
        roomTypeIds: [this.prestation.roomTypeId],
        mealPlanTypeIds: this.prestation.mealPlanTypeId,
        extras: this.prestation.data.extras.map((e) => [e.id, e.included])
      });
      post(
          "/api/prestation/modify/extras?prestationId=" + this.prestation.id,
          ctx
      ).json(() => {
        EventBus.$emit("reloadbooking", {});
        console.log("reload done")
        this.$emit("close");
      });
    },
  },
};
</script>
<style lang="less">
.selected {
  background-color: #4285f4;
  color: #fff;
  //text-shadow:1px 1px 2px black;
  z-index: 1;
  padding: 5px;
}

.selectable {
  cursor: pointer;
}

.list-horizontal li {
  display: inline-block;
}

.list-horizontal li:before {
  content: "\00a0\2022\00a0\00a0";
  color: #999;
  color: rgba(0, 0, 0, 0.5);
  font-size: 11px;
}

.list-horizontal li:first-child:before {
  content: "";
}
</style>
